export default [
    '#3699ff',
    '#1bc5bd',
    '#f64e60',
    '#ffa800',
    '#ac3e31',
    '#dbae68',
    '#4cb5f5',
    '#1c4e80',
    '#0091d5',
    '#6ab187',
    '#488a99',
   

]