










































import AppMiniChart from '@/utils/chart/components/MiniChart.vue'
import todaysRevenue from '@/charts/line/mini/todaysRevenue'
// import onlineRevenue from '@/charts/bar/mini/onlineRevenue'
import grossProfit from '@/charts/line/mini/grossProfit'
import openCashTray from '@/charts/bar/mini/openCashTray'
import dailySales from '@/charts/bar/dailySales'
import topSales from '@/charts/pie/topSales'

import AppChart from '@/utils/chart/components/Chart.vue'
import monthlySales from '@/charts/line/monthlySales'
import Vue from "vue";

export default Vue.extend({
  name: "Home",
  data(){
    return {
      monthlySales,
      topSales,
      todaysRevenue,
      grossProfit,
      openCashTray,
      dailySales
    }
  },
  components:{
    AppChart,
    AppMiniChart
  }
});
