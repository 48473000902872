













import Vue from "vue";
import {mapGetters} from 'vuex'

export default Vue.extend({
  name: "SnackBar",
  computed:{
     ...mapGetters({
      snackBar: 'ui/snackBar'
    })
  },
   data: () => ({
      multiLine: true,
      snackbar: false,
      text: `I'm a multi-line snackbar.`,
    }),
  
});
