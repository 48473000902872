









import Vue from "vue";
import ChartMixin from '@/mixins/chartMixin'
export default Vue.extend({
  mixins: [ChartMixin],

});
